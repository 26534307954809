import { getPublisher, getCreator } from 'lib/headerMetadata';
import { getCloudinaryFlavoredImage } from 'lib/imageUtils';
import { shareImage } from 'lib/social';
import { buildOrganization } from './buildOrganization';

import { cloudinaryFlavorLd } from './cloudinaryFlavorLd';

// TODO, can this be combined with buildNewsArticle?
export const buildVideoNewsArticle = (video, vertical) => {
  const {
    headline = { seo: '' },
    description = { seo: '', primary: '' },
    url = { primary: '' },
    date = {
      createdAt: '',
      publishedAt: '',
      modifiedAt: '',
    },
    primarySection = '',
    mpxId = '',
    primaryImage = { url: {} },
    keywords = '',
  } = video;

  const imageUrl = primaryImage?.url?.primary ?? {};

  const ldjson = {
    '@context': {
      '@vocab': 'http://schema.org',
      articleId: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'NewsArticle',
    headline: headline.seo,
    description: description.seo,
    url: url.primary,
    thumbnailUrl: shareImage(imageUrl, vertical),
    creator: getCreator(vertical),
    alternativeHeadline: headline.seo,
    dateCreated: date.createdAt,
    datePublished: date.publishedAt,
    dateModified: date.modifiedAt,
    articleSection: (primarySection?.slug) || '',
    articleId: mpxId,
    identifier: {
      '@type': 'PropertyValue',
      propertyID: 'uid',
      value: mpxId,
    },
    image: primaryImage ? {
      '@type': 'ImageObject',
      url: getCloudinaryFlavoredImage(primaryImage.url.primary, cloudinaryFlavorLd),
    } : '',
    author: [{
      ...buildOrganization(vertical),
    }],
    publisher: getPublisher(vertical),
    keywords,
  };

  return ldjson;
};
