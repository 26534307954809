/**
* We are grabbing FAQs if it exists
* if widget, properties, embed or faqs are undefined, we return an empty array
* @param {object} article
*
* @return {Array}
*/
export function getFAQS(article) {
  const {
    body = [],
  } = article;

  const embeddedWidget = body.find((el) => el.type === 'embeddedWidget' && el.widget?.properties?.embed?.faqs);

  return embeddedWidget?.widget?.properties?.embed?.faqs || [];
}
