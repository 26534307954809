import getConfig from 'next/config';

import { shareImage } from 'lib/social';
import { getContentUrl } from 'lib/urlUtils';

/**
 * Video
 * @param {Video} video
 * @param {VerticalType} vertical
 */
export const buildVideo = (video, vertical) => {
  const {
    date,
    duration = '',
    primaryImage = { url: {} },
    nativeAd = false,
    primarySection = '',
    primarySubvertical = '',
    primaryTopic = '',
    source = '',
    tags = '',
    url = { primary: '' },
    videoAssets,
  } = video;

  const imageUrl = primaryImage?.url?.primary ?? {};
  const contentUrl = getContentUrl(videoAssets);

  const ldJsonVideo = {
    '@context': {
      '@vocab': 'http://schema.org',
      pageType: {
        '@id': 'Text',
        '@type': '@id',
      },
      vertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      subVertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      section: {
        '@id': 'Text',
        '@type': '@id',
      },
      subSection: {
        '@id': 'Text',
        '@type': '@id',
      },
      packageId: {
        '@id': 'Text',
        '@type': '@id',
      },
      sponsor: {
        '@id': 'Text',
        '@type': '@id',
      },
      appVersion: {
        '@id': 'Text',
        '@type': '@id',
      },
      thumbnailUrl: {
        '@id': 'Text',
        '@type': '@id',
      },
      uploadDate: {
        '@id': 'Text',
        '@type': '@id',
      },
      contentUrl: {
        '@id': 'Text',
        '@type': '@id',
      },
      duration: {
        '@id': 'Text',
        '@type': '@id',
      },
      tags: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'Dataset',
    name: 'additionalTaxonomy',
    description: 'This is additional taxonomy that helps us with analytics',
    url: url.primary,
    pageType: 'Video', // need lowercase later, and try to not be hardcoded
    vertical,
    subVertical: (primarySubvertical?.slug) || '',
    section: (primarySection?.slug) || '',
    subSection: (primaryTopic?.slug) || '',
    packageId: '', // packages are for fronts?
    sponsor: nativeAd ? source?.name : '', // not sure there is source for slideshow
    appVersion: getConfig()?.publicRuntimeConfig?.appVersion ?? '',
    thumbnailUrl: shareImage(imageUrl, vertical),
    uploadDate: date?.publishedAt,
    contentUrl,
    duration,
    tags,
  };

  return ldJsonVideo;
};
