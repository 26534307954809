import flatten from 'lodash.flatten';

import getISODate from 'lib/getISODate';
import { getPublisher, getCreator } from 'lib/headerMetadata';
import { shareImage } from 'lib/social';
import { useGateAccess } from 'lib/Hooks';

import { buildAuthors } from './buildAuthors';
import { getContentUrl } from './getContentUrl';
import { getUniqueAdditionalTermsReg } from './getUniqueAdditionalTermsReg';

const getIngredientsFromRecipe = (ingredients) => {
  if (!ingredients || !Array.isArray(ingredients)) {
    return [];
  }

  return flatten(ingredients.map((ingredient) => {
    const ingreds = ingredient.ingredients || [];
    return ingreds.map((item) => (
      item.description
    ));
  }));
};

const buildRecipeInstructions = (instructions, vertical) => {
  if (!instructions || !Array.isArray(instructions)) {
    return [];
  }

  return instructions.map((howTo) => {
    if (howTo.type === 'embeddedImage') {
      const { image = {} } = howTo;
      return {
        '@type': 'HowToStep',
        text: image?.headline?.seo ?? '',
        image: image?.url?.primary ?? '',
      };
    }

    if (howTo.type === 'embeddedVideo') {
      const { video = {} } = howTo;
      const videoImageUrl = video?.primaryImage?.url?.primary ?? '';
      return {
        '@type': 'HowToStep',
        text: video?.headline?.seo ?? '',
        video: {
          '@type': 'VideoObject',
          name: video?.headline?.seo ?? '',
          description: video?.description?.seo ?? '',
          thumbnailUrl: shareImage(videoImageUrl, vertical),
          uploadDate: getISODate(video.datePublished),
          duration: video.duration,
          url: video?.url?.primary ?? '',
        },
      };
    }

    const embeddedTextHTML = howTo.html.trim();
    const startsWithNumber = /^\s?\d+[.\s]/;
    const newHtmlString = embeddedTextHTML.replace(startsWithNumber, '');

    if (embeddedTextHTML) {
      return {
        '@type': 'HowToStep',
        text: newHtmlString,
      };
    }
    return null;
  });
};

const buildGateValue = (recipe) => {
  const chefNotes = recipe.chefNotes && Array.isArray(recipe.chefNotes)
    ? recipe.chefNotes.map((note) => note.html).join(' ')
    : '';

  const ingredients = recipe.ingredients && Array.isArray(recipe.ingredients)
    ? recipe.ingredients.map((list) => list.ingredients.map((ingredient) => ingredient.description).join(' ')).join(' ')
    : '';

  const instructions = recipe.instructions && Array.isArray(recipe.instructions)
    ? recipe.instructions.map((instruction) => instruction.html || '').join(' ')
    : '';

  return `${chefNotes} ${ingredients} ${instructions}`;
};

export const buildRecipe = (recipe, vertical) => {
  const {
    description: {
      primary: descriptionPrimary,
      seo: descriptionSeo,
      social: descriptionSocial,
      tease: descriptionTease,
    },
    headline: {
      primary: headlinePrimary,
      seo: headlineSeo,
      social: headlineSocial,
      tease: headlineTease,
    },
    authors,
    associatedVideo,
    dateModified,
    datePublished,
    cookTime,
    prepTime,
    aggregateRating,
    yield: recipeYield,
    id,
    ingredients,
    instructions,
    nutrition,
    totalTime,
    teaseImage,
    primaryImage,
    taxonomy,
    url,
  } = recipe;

  const {
    ratingCount,
    ratingValue,
  } = aggregateRating ?? {};

  const image = teaseImage || primaryImage || {};
  const imageUrl = image?.url?.primary ?? '';
  const {
    recipeCategories,
    cuisines,
    courseTypes,
  } = taxonomy || {};
  const category = courseTypes || [];
  const keywords = recipeCategories || [];
  const recipeCuisines = cuisines || [];

  const hasAccess = useGateAccess(recipe);

  const recipeLdJson = {
    '@context': {
      '@vocab': 'https://schema.org/',
      articleId: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'Recipe',
    name: headlineSeo || headlineSocial || headlinePrimary || headlineTease,
    author: buildAuthors({ authors, vertical }),
    dateCreated: getISODate(datePublished),
    datePublished: getISODate(datePublished),
    dateModified: getISODate(dateModified),
    description: descriptionSeo || descriptionSocial || descriptionPrimary || descriptionTease,
    image: image.url ? {
      '@type': 'ImageObject',
      url: image.url.primary,
      caption: image.caption,
    } : '',
    prepTime,
    cookTime,
    totalTime,
    recipeYield,
    nutrition: {
      '@type': 'NutritionInformation',
      calories: nutrition ? nutrition.calories : '',
    },
    recipeIngredient: getIngredientsFromRecipe(ingredients),
    recipeInstructions: buildRecipeInstructions(instructions, vertical),
    recipeCategory: getUniqueAdditionalTermsReg(category, /\w\/courseType\/\w?/),
    recipeCuisine: getUniqueAdditionalTermsReg(recipeCuisines, /\w\/cuisine\/\w?/),
    keywords: getUniqueAdditionalTermsReg(keywords, /\w\/recipeCategory\/\w?/),
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: ratingValue || 0,
      ratingCount: ratingCount || 0,
    },
    thumbnailUrl: shareImage(imageUrl, vertical),
    articleId: id,
    creator: getCreator(vertical),
    headline: headlineSeo || headlineSocial || headlinePrimary || headlineTease,
    alternativeHeadline: headlinePrimary,
    identifier: {
      '@type': 'PropertyValue',
      propertyID: 'uid',
      value: id,
    },
    publisher: getPublisher(vertical),
    url: url.primary,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url.primary,
    },
  };

  if (associatedVideo) {
    const {
      headline: {
        primary: videoHeadlinePrimary,
        seo: videoHeadlineSeo,
        social: videoHeadlineSocial,
        tease: videoHeadlineTease,
      } = {},
      url: videoUrl = {},
      description: {
        primary: videoDescriptionPrimary,
        seo: videoDescriptionSeo,
        social: videoDescriptionSocial,
        tease: videoDescriptionTease,
      } = {},
      duration,
      date,
      primaryImage: videoPrimaryImage,
      videoAssets,
    } = associatedVideo;

    const contentUrl = getContentUrl(videoAssets);

    const videoImageUrl = videoPrimaryImage?.url?.primary ?? '';

    recipeLdJson.video = {
      '@type': 'VideoObject',
      name:
        videoHeadlineSeo
        || videoHeadlineSocial
        || videoHeadlinePrimary
        || videoHeadlineTease,
      url: videoUrl.primary,
      description:
        videoDescriptionSeo
        || videoDescriptionSocial
        || videoDescriptionPrimary
        || videoDescriptionTease,
      thumbnailUrl: shareImage(videoImageUrl, vertical),
      contentUrl,
      duration,
      uploadDate: date?.publishedAt,
    };
  }

  if (!hasAccess) {
    recipeLdJson.isAccessibleForFree = 'False';
    recipeLdJson.hasPart = {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: '.recipe-body__content',
      value: buildGateValue(recipe),
    };
  }

  return recipeLdJson;
};
